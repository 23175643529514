import React, { useEffect, useState } from "react";
import MyCustomSelect from "../../common/elements/myCustomSelect";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { openNotification } from "../../common/elements/myNotification";
import { postCoderReview } from "../../api/CoderReviewApi";
import MyCustomSelectAsync from "../../common/elements/myCustomSelectAsync";

const dateFormat = ["MM-DD-YYYY"];

const CoderReviewForm = ({
  icd10Codes,
  validationStatusOptions,
  noteTypeOptions,
  editRecord,
  callback,
  provList,
}) => {
  const [formData, setFormData] = useState(
    editRecord?.guid
      ? {
          ...editRecord,
          modifiedBy: sessionStorage.getItem("userName"),
          dateOfService:
            dayjs(editRecord.dateOfService).format("YYYY-MM-DD HH:mm A") ===
            "1900-01-01 00:00 AM"
              ? ""
              : editRecord.dateOfService,
        }
      : {
          ...editRecord,
        }
  );

  const [icd10, setIcd10] = useState();
  const [validationStatus, setValidationStatus] = useState();
  const [noteType, setNoteType] = useState();
  const [renderingProvider, setRenderingProvider] = useState();
  const [pageError, setPageError] = useState({});
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (editRecord.guid) {
      let icd10Temp = [];
      if (editRecord?.icd10Code) {
        icd10Temp = icd10Codes?.filter((item) => {
          return item.value === editRecord?.icd10Code;
        });
        setIcd10(icd10Temp);
      }
      let validationStatusTemp = [];
      if (editRecord?.validationStatusValue) {
        validationStatusTemp = validationStatusOptions?.filter((item) => {
          return item.value === editRecord?.validationStatusValue;
        });
        setValidationStatus(validationStatusTemp);
      }

      let noteTypeTemp = [];
      if (editRecord?.noteTypeValue) {
        noteTypeTemp = noteTypeOptions?.filter((item) => {
          return item.value === editRecord?.noteTypeValue;
        });
        setNoteType(noteTypeTemp);
      }

      let renderingProviderTemp = [];
      if (editRecord?.renderProvider) {
        renderingProviderTemp = provList?.filter((item) => {
          return item.value === editRecord?.renderProvider;
        });
        setRenderingProvider(renderingProviderTemp);
      }
    }
  }, [editRecord]);

  const onIcd10Change = (e) => {
    setIcd10(e);
    setFormData({
      ...formData,
      icd10Code: e ? e.value : "",
    });
    setPageError({ ...pageError, icd10Code: e ? "" : "Required" });
  };

  const onValidationStatusChange = (e) => {
    setValidationStatus(e);
    setFormData({
      ...formData,
      validationStatusLabel: e ? e.label : "",
      validationStatusValue: e ? e.value : "",
    });
    setPageError({ ...pageError, validationStatusValue: e ? "" : "Required" });
  };

  const onNoteTypeChange = (e) => {
    setNoteType(e);
    setFormData({
      ...formData,
      noteTypeLabel: e ? e.label : "",
      noteTypeValue: e ? e.value : "",
    });
    // setPageError({ ...pageError, noteTypeValue: e ? "" : "Required" });
  };

  const onRenderingProviderChange = (e) => {
    setRenderingProvider(e);
    setFormData({
      ...formData,
      renderProvider: e ? e.value : "",
    });
    // setPageError({ ...pageError, renderProvider: e ? "" : "Required" });
  };

  const onDateOfServiceChange = (date, dateString) => {
    setFormData({
      ...formData,
      dateOfService: dateString ? dateString : "",
    });
    setPageError({
      ...pageError,
      dateOfService: dateString ? "" : "Required",
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setPageError({});

    let pageErrorTemp = {};

    if (!formData?.validationStatusValue) {
      pageErrorTemp.validationStatusValue = "Required";
    }

    if (!formData?.icd10Code) {
      pageErrorTemp.icd10Code = "Required";
    }

    if (Object.keys(pageErrorTemp).length > 0) {
      setPageError(pageErrorTemp);
      return;
    }

    onSave(e);
  };

  const onSave = async (e) => {
    setDisable(true);
    try {
      const response = await postCoderReview(formData);
      console.log("response", response);
      if (response?.status === 200) {
        if (
          response?.data?.mbi &&
          response?.data?.message ===
            "Record Exists with Dx code for the Patient "
        ) {
          openNotification(
            "warning",
            "Warning",
            "This ICD-10 code has already been added to a record."
          );
        } else if (!formData?.guid) {
          openNotification(
            "success",
            "Added",
            "Coder Review Added Successfully!",
            "bottomRight",
            3
          );
          setFormData(editRecord);
          callback(response);
        } else if (formData?.guid) {
          openNotification(
            "success",
            "Updated",
            "Coder Review Updated Successfully!",
            "bottomRight",
            3
          );
          setFormData(editRecord);
          callback(response);
        }
      } else {
        openNotification("error", "Error", "Coder Review Failed to Add!");
      }
    } catch (e) {
      return e;
    }
    setDisable(false);
  };

  return (
    <div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold tw-mb-1" htmlFor="visitType">
          ICD 10 Code
        </label>
        <MyCustomSelectAsync
          selectOptions={icd10Codes}
          myCallBack={onIcd10Change}
          selectedValues={icd10}
          isMulti={false}
          id={"visitType"}
          errorMessage={pageError?.icd10Code}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold tw-mb-1" htmlFor="visitType">
          Validation Status
        </label>
        <MyCustomSelect
          selectOptions={validationStatusOptions}
          myCallBack={onValidationStatusChange}
          selectedValue={validationStatus}
          errorMessage={pageError?.validationStatusValue}
          id={"validationStatus"}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold tw-mb-1" htmlFor="visitType">
          Note Type
        </label>
        <MyCustomSelect
          selectOptions={noteTypeOptions}
          myCallBack={onNoteTypeChange}
          selectedValue={noteType}
          id={"noteType"}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold tw-mb-1" htmlFor="visitType">
          Rendering Provider
        </label>
        <MyCustomSelect
          selectOptions={provList}
          myCallBack={onRenderingProviderChange}
          selectedValue={renderingProvider}
          id={"renderingProvider"}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold tw-mb-1" htmlFor="visitType">
          Date of Service
        </label>
        <DatePicker
          className="form-control"
          onChange={onDateOfServiceChange}
          format={dateFormat}
          value={
            formData?.dateOfService &&
            dayjs(formData?.dateOfService).isValid() &&
            dayjs(formData?.dateOfService).format("YYYY-MM-DD hh:mm A") !==
              "1900-01-01 12:00 AM"
              ? dayjs(formData?.dateOfService)
              : null
          }
          id="dateOfService"
        />
      </div>

      <div className="form-group tw-fixed tw-top-2 tw-right-10">
        <button
          className={
            !disable ? "btn btn-success " : "btn btn-success disabled "
          }
          onClick={onSubmit}
        >
          {editRecord?.guid ? "Update" : "Save"}
        </button>
        <button
          className="btn btn-secondary tw-ml-4"
          onClick={() => callback(false)}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default CoderReviewForm;
