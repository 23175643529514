import React, { useState } from "react";
import {
  Button,
  Space,
  Table,
  Drawer,
  Tooltip,
  Popconfirm,
  Skeleton,
} from "antd";
import Search from "antd/es/input/Search";
import { FaCalendarAlt, FaEdit, FaRegTrashAlt } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { WarningOutlined } from "@ant-design/icons";
import VisitTrackingForm from "./VisitTrackingForm";
import { fetcherhn } from "../../services/fectcher";
import useSWR from "swr";
import dayjs from "dayjs";
import { deleteVisitTracking } from "../../api/VisitTrackingApi";
import { openNotification } from "../../common/elements/myNotification";
import { IoArrowBackCircle } from "react-icons/io5";
import { useMyContext } from "../../common/elements/myContext";
import { usePickList } from "../../services/queries";
import { MdEventNote } from "react-icons/md";
import PatientBanner from "../../common/elements/PatientBanner";
import useSWRImmutable from "swr/immutable";

const VisitTrackingPatient = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { utcToLocal } = useMyContext();
  const { patientData } = location.state || {};

  const schedulingDetailOptions = usePickList(50)?.data;
  const visitTypeOptions = usePickList(51)?.data;
  const statusOptions = usePickList(52)?.data;

  const { data, mutate, isLoading } = useSWR(
    `GetCceRecords?id=${patientData?.mbi}`,
    fetcherhn
  );

  const { data: provList } = useSWRImmutable(
    `GetNpPaList?nppa_for=CODERWORKFLOW`,
    fetcherhn
  );

  const providerMap = provList?.reduce((acc, provider) => {
    acc[provider.value] = provider.label;
    return acc;
  }, {});

  const initialReqState = {
    key: "",
    guid: "",
    mbi: patientData?.mbi,
    visitTypeLabel: "",
    visitTypeValue: "",
    visitTypeOther: "",
    visitStatusLabel: "",
    visitStatusValue: "",
    visitStatusOther: "",
    visitStatusScheduledDate: "",
    schedulingDetailsLabel: "",
    schedulingDetailsValue: "",
    schedulingDetailDate: "",
    notes: "",
    renderProvider: "",
    lastUpdatedDate: "",
    createdBy: sessionStorage?.getItem("userName") || "",
    modifiedBy: "",
  };

  const [editRecord, setEditRecord] = useState(initialReqState);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [query, setQuery] = useState("");

  const handleCancel = () => {
    setIsModalOpen(false);
    setEditRecord(initialReqState);
  };

  const handleOk = async (e) => {
    if (e) {
      mutate();
    }
    setIsModalOpen(false);
    setEditRecord(initialReqState);
  };

  const edit = (record) => {
    setIsModalOpen(true);
    setEditRecord(record);
  };

  const handleDelete = async (record) => {
    const body = { ...record, modifiedBy: sessionStorage.getItem("userName") };
    const deleteResult = await deleteVisitTracking(body);
    if (deleteResult.status) {
      mutate();
      openNotification(
        "success",
        "Deleted",
        "Visit Tracking Deleted Successfully!"
      );
    }
  };

  const columns = [
    {
      title: "Last Updated Date",
      dataIndex: "lastUpdatedDate",
      width: 180,
      sorter: (a, b) => a.lastUpdatedDate.localeCompare(b.lastUpdatedDate),
      render: (text) => (text && dayjs(text).isValid() ? utcToLocal(text) : ""),
    },
    {
      title: "Visit Type",
      dataIndex: "visitTypeLabel",
      render: (text, record) => {
        return (
          <>
            <div>{text}</div>
            {record.visitTypeLabel === "Other" && (
              <div className=" tw-text-gray-600">
                <MdEventNote size={20} className=" tw-mr-1 tw-text-gray-600" />
                {record.visitTypeOther}
              </div>
            )}
          </>
        );
      },
    },
    {
      title: "Visit Status",
      dataIndex: "visitStatusLabel",
      render: (text, record) => {
        let formattedDate = "";
        if (
          record.visitStatusScheduledDate &&
          dayjs(record.visitStatusScheduledDate).isValid()
        ) {
          const tempDate = dayjs(record.visitStatusScheduledDate).format(
            "YYYY-MM-DD hh:mm A"
          );
          formattedDate = tempDate === "1900-01-01 12:00 AM" ? "" : tempDate;
        }

        return (
          <div>
            <div>{text}</div>
            {formattedDate && (
              <div className="tw-flex tw-items-center tw-space-x-2 tw-text-gray-600">
                <FaCalendarAlt size={16} className="tw-align-middle " />
                <p className="tw-mb-0">{formattedDate}</p>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "Scheduling Details",
      dataIndex: "schedulingDetailsLabel",
    },
    {
      title: "Scheduling Detail Date",
      dataIndex: "schedulingDetailDate",
      render: (text) => {
        if (text && dayjs(text).isValid()) {
          const formattedDate = dayjs(text).format("YYYY-MM-DD hh:mm A");
          return formattedDate === "1900-01-01 12:00 AM" ? "" : formattedDate;
        }
        return "";
      },
    },
    {
      title: "Rendering Provider",
      dataIndex: "renderProvider",
      render: (value) => providerMap?.[value] || value,
    },
    {
      title: "Notes",
      dataIndex: "notes",
    },
    {
      key: "key",
      title: "Operation",
      dataIndex: "guid",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <div>
          <Space>
            <Tooltip title="Edit">
              <FaEdit
                size={20}
                className=" tw-cursor-pointer"
                onClick={() => edit(record)}
                color="blue"
              />
            </Tooltip>
            <Tooltip title="Delete">
              <Popconfirm
                title="Ok to Delete?"
                onConfirm={() => handleDelete(record)}
                icon={
                  <WarningOutlined
                    style={{
                      color: "red",
                    }}
                  />
                }
              >
                <FaRegTrashAlt
                  size={20}
                  className=" tw-cursor-pointer"
                  color="red"
                />
              </Popconfirm>
            </Tooltip>
          </Space>
        </div>
      ),
    },
  ];

  const finalResult = data
    ? data?.filter((item) => {
        return [
          "visitTypeLabel",
          "visitTypeValue",
          "visitTypeOther",
          "visitStatusLabel",
          "visitStatusValue",
          "visitStatusOther",
          "visitStatusScheduledDate",
          "visitStatusChartReviewDate",
          "visitStatusCompletedDate",
          "schedulingDetailsLabel",
          "schedulingDetailsValue",
          "schedulingDetailsOther",
          "schedulingDetailDate",
          "notes",
          "lastUpdatedDate",
          "createdBy",
          "modifiedBy",
          "renderProvider",
        ].some((key) => {
          if (key === "lastUpdatedDate" && item[key]) {
            return utcToLocal(item[key])
              .toLowerCase()
              .includes(query.toLowerCase().replace(/_/g, " "));
          } else if (key === "renderProvider" && providerMap) {
            return providerMap[item[key]]
              ?.toLowerCase()
              .includes(query.toLowerCase().replace(/_/g, " "));
          }
          return (
            item[key]?.toString()?.toLowerCase().replace(/_/g, " ") ?? ""
          ).includes(query?.toLowerCase().replace(/_/g, " ") ?? "");
        });
      })
    : data;

  return (
    <>
      <div className="tw-flex tw-justify-between">
        <Space>
          <IoArrowBackCircle
            className="tw-cursor-pointer"
            size="2em"
            onClick={() =>
              navigate("/TaskManagement/VisitTracking", {
                state: {
                  query: location?.state?.query,
                  page: location?.state?.page,
                  provider: location?.state?.provider,
                  cmsProvider: location?.state?.cmsProvider,
                  status: location?.state?.status,
                  pageSize: location?.state?.pageSize,
                },
              })
            }
          />
          <p
            className="pt-3 tw-text-xl"
            style={{
              color: "#D2691E",
              fontWeight: "600",
            }}
          >
            Visit Tracking for {patientData?.patientName}
          </p>
        </Space>

        <div className="tw-flex tw-justify-end tw-mt-3">
          <Space>
            <Search
              className=" tw-mb-4"
              size="middle"
              placeholder="Search"
              onChange={(e) => setQuery(e.target.value)}
              allowClear
            />
            <Button
              type="primary"
              onClick={() => setIsModalOpen(true)}
              className="tw-mb-4"
            >
              Add
            </Button>
          </Space>
        </div>
      </div>
      <PatientBanner patientData={patientData} />

      {isModalOpen && (
        <Drawer
          open={isModalOpen}
          title="Add/Update Visit Tracking"
          onClose={handleCancel}
          width={600}
        >
          <VisitTrackingForm
            visitTypeOptions={visitTypeOptions}
            statusOptions={statusOptions}
            schedulingDetailOptions={schedulingDetailOptions}
            editRecord={editRecord}
            callback={handleOk}
            provList={provList}
          />
        </Drawer>
      )}

      {isLoading ? (
        <Skeleton active={isLoading} />
      ) : (
        <Table
          className="tw-mt-4"
          dataSource={finalResult}
          columns={columns}
          virtual
          bordered
          scroll={{
            x: 2000,
            y: 1000,
          }}
        />
      )}
    </>
  );
};

export default VisitTrackingPatient;
