import { axiosInstancehn } from "./AxiosInstance";

export async function postCoderReview(body) {
  return axiosInstancehn
    .post("/AddCoderWorkFlow", body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function deleteCoderReview(body) {
  return axiosInstancehn
    .delete("/DeleteCoderWorkFlow", { data: body })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
