import React, { useState } from "react";
import {
  Button,
  Drawer,
  Popconfirm,
  Skeleton,
  Space,
  Table,
  Tooltip,
} from "antd";
import { AddTuckInCall } from "./AddTuckInCall";
import dayjs from "dayjs";
import Search from "antd/es/input/Search";
import useSWR from "swr";
import { fetcherhn } from "../../services/fectcher";

import { WarningOutlined } from "@ant-design/icons";
import { deleteTuckInCall } from "../../api/CareEventsApi";
import { openNotification } from "../../common/elements/myNotification";
import { usePickList } from "../../services/queries";
import { handleToggle } from "../../common/elements/toggleSeeMore";
import { FaEdit, FaRegTrashAlt } from "react-icons/fa";
import { FiSend } from "react-icons/fi";
import SendEmail from "../../common/elements/SendEmail";

export const TuckInCall = (props) => {
  const intialReqState = {
    key: "",
    guid: "",
    mbi: props.mbiid || "",
    location: "",
    locationOtherNotes: "",
    levelOfCare: "",
    codeStatusOnFile: "No",
    isMedicationArrived: "Yes",
    medicationDeliveryNotes: "",
    isThereNewMedication: "No",
    newMedicationNotes: "",
    isNCPFollowUpCallSetUpDone: "Yes",
    ncpFollowUpCallNotes: "",
    nurseSummaryNote: "",
    createdBy: sessionStorage.getItem("userName") || "",
    modifiedBy: "",
    followupDateTime: "",
  };

  const [query, setQuery] = useState("");

  const [editRecord, setEditRecord] = useState(intialReqState);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data, mutate, isLoading } = useSWR(
    `GetTuckInCall?id=${props.mbiid}`,
    fetcherhn
  );
  const picklist22 = usePickList(22).data;
  const picklist21 = usePickList(21).data;

  const [expandedRows, setExpandedRows] = useState([]);

  const [mail, setMail] = useState(false);

  const sendDatatoEmail = (record) => {
    setMail(true);
    setEditRecord(record);
  };

  const handleModalClose = (e) => {
    setEditRecord(intialReqState);
    setMail(false);
  };

  const columns = [
    {
      key: 1,
      title: "Location",
      dataIndex: "location",
      width: "10%",
    },
    {
      key: 2,
      title: "Level of Care",
      dataIndex: "levelOfCareLabel",
      width: "10%",
    },
    {
      key: 3,
      title: "POLST/AD/CodeStatus on File",
      dataIndex: "codeStatusOnFile",
      width: "12%",
      render: (text) => {
        if (text === "Unk") {
          return <>Unknown</>;
        } else {
          return text;
        }
      },
    },

    {
      key: 4,
      title: "Nurse Summary Note",
      dataIndex: "nurseSummaryNote",
      width: "43%",
      render: (text, record) => {
        const isExpanded = expandedRows.includes(record.key);
        const content =
          text.length > 100 ? (
            <span>
              {isExpanded ? text : `${text.substring(0, 100)}...`}
              <Button
                type="link"
                onClick={() => handleToggle(record.key, setExpandedRows)}
              >
                {isExpanded ? "See less" : "See more"}
              </Button>
            </span>
          ) : (
            text
          );

        return <div>{content}</div>;
      },
    },

    {
      key: 5,
      width: "15%",
      title: "Follow-up Date & Time",
      dataIndex: "followupDateTime",
      render: (_, { followupDateTime, createdBy }) => {
        const [name] = createdBy.split("@");
        return (
          <>
            <div>
              {dayjs(followupDateTime).format("YYYY-MM-DD") !== "1900-01-01" ? (
                dayjs(followupDateTime).format("YYYY-MM-DD hh:mm A")
              ) : (
                <></>
              )}
            </div>
            <div>{name}</div>
          </>
        );
      },
    },
    {
      key: "key",
      title: "Operation",
      dataIndex: "guid",
      fixed: "right",
      width: 110,
      render: (_, record) => (
        <div>
          <Space>
            <Tooltip title="Edit">
              <FaEdit
                size={20}
                className=" tw-cursor-pointer"
                onClick={() => edit(record)}
                color="blue"
              />
            </Tooltip>
            <Tooltip title="Delete">
              <Popconfirm
                title="Ok to Delete?"
                onConfirm={() => handleDelete(record)}
                icon={
                  <WarningOutlined
                    style={{
                      color: "red",
                    }}
                  />
                }
              >
                <FaRegTrashAlt
                  size={20}
                  className=" tw-cursor-pointer"
                  color="red"
                />
              </Popconfirm>
            </Tooltip>
            <Tooltip title="Send Email">
              <FiSend
                size={20}
                className=" tw-cursor-pointer"
                onClick={() => sendDatatoEmail(record)}
                color="green"
              />
            </Tooltip>
          </Space>
        </div>
      ),
    },
  ];

  const edit = (record) => {
    setIsModalOpen(true);
    setEditRecord(record);
  };

  const handleDelete = async (record) => {
    const body = {
      ...record,
      modifiedBy: sessionStorage.getItem("userName"),
    };
    const deleteResult = await deleteTuckInCall(body);
    if (deleteResult.status) {
      mutate();
      openNotification(
        "success",
        "Deleted",
        "Tuck In Call Deleted Successfully!"
      );
    }
  };
  const handleOk = async (e) => {
    mutate();
    setIsModalOpen(false);
    setEditRecord(intialReqState);
  };

  const handleCancel = (e) => {
    setIsModalOpen(false);
    setEditRecord(intialReqState);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const finalResult = data
    ? data.filter((item) => {
        return [
          "location",
          "levelOfCare",
          "nurseSummaryNote",
          "codeStatusOnFile",
          "createdBy",
          "followupDateTime",
        ].some((key) => {
          const fieldValue = item[key]?.toString()?.toLowerCase() ?? "";
          const searchTerm = query?.toLowerCase() ?? "";

          // Replace 'unk' with 'unknown' in the 'codeStatusOnFile' field
          const normalizedValue =
            key === "codeStatusOnFile" && fieldValue === "unk"
              ? "unknown"
              : fieldValue;

          return normalizedValue.includes(searchTerm);
        });
      })
    : data;

  if (isLoading) {
    return <Skeleton active={isLoading} />;
  }

  return (
    <div>
      <div className="tw-flex tw-justify-between ">
        <p
          className="pt-3"
          style={{
            color: "#D2691E",
            fontWeight: "600",
          }}
        >
          Tuck in Call
        </p>

        <div className="tw-flex tw-justify-end  tw-mt-3 ">
          <Space>
            <Search
              className=" tw-mb-4"
              size="middle"
              placeholder="Search"
              onChange={(e) => setQuery(e.target.value)}
              allowClear
            />
            <Button type="primary" onClick={showModal} className="tw-mb-4 ">
              Add
            </Button>
          </Space>
        </div>
      </div>
      {isModalOpen && (
        <Drawer
          open={isModalOpen}
          title={"Add/Update Tuck In Call"}
          onClose={handleCancel}
          width={570}
        >
          <AddTuckInCall
            callback={handleOk}
            editRecord={editRecord}
            pickList21={picklist21}
            pickList22={picklist22}
          />
        </Drawer>
      )}
      <Table
        size="middle"
        dataSource={finalResult}
        bordered
        columns={columns}
      />

      {mail && (
        <SendEmail
          open={mail}
          close={handleModalClose}
          editRecord={editRecord}
          modalTitle={"Tuck In Call"}
          patientName={props?.patient?.label}
        />
      )}
    </div>
  );
};
