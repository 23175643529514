import { Drawer } from "antd";
import React, { useEffect, useState } from "react";
import { sendEmail } from "../../api/CareEventSCPApi";
import {
  GrievanceColumns,
  AdmReAdmColumns,
  ChangeinConditionColumns,
  DMEColumns,
  EDColumns,
  HighRiskColumns,
  HospAdmColumns,
  HospDischColumns,
  IPHospitalCommColumns,
  NotesColumns,
  NurseColumns,
  OrderColumns,
  OutboundColumns,
  TherapyColumns,
  TierChangeColumns,
  TriageColumns,
  TuckInCallColumns,
} from "../../components/CareEvent/ColumnsGroup";
import dayjs from "dayjs";
import { useMyContext } from "./myContext";
import { openNotification } from "./myNotification";
import { Editor, EditorState, RichUtils, convertFromRaw } from "draft-js";
import "draft-js/dist/Draft.css";
import { convertToHTML } from "draft-convert";

const SendEmail = ({ open, close, editRecord, modalTitle, patientName }) => {
  const { utcToLocal } = useMyContext();
  const { formatPhoneNumber } = useMyContext();

  const [disable, setDisable] = useState(false);

  const fromName = sessionStorage.getItem("userName");

  const [mailData, setMailData] = useState({
    sendMailFrom: fromName,
    sendMailto: [],
    sendMailcc: [],
    subject: "",
    messageBody: "",
    dueDate: "",
  });
  const [pageError, setPageError] = useState({});

  const [editorState, setEditorState] = useState(() =>
    EditorState?.createEmpty()
  );

  const handleInputChange = (name, value) => {
    const emailRegex = /^[^\s@]+@careconnectmd\.com$/;

    // Helper function to check if an array of emails contains an invalid email
    const checkForInvalidEmails = (emails) => {
      return emails.some((email) => email && !emailRegex.test(email));
    };

    // Parse input to get list of emails
    const parseEmails = (input) => {
      return input.split(",").map((email) => email.trim().toLowerCase());
    };

    // Initialize variables
    let newMailData = { ...mailData };
    let newPageError = { ...pageError };
    let disableButton = false;

    if (name === "sendMailto" || name === "sendMailcc") {
      const emails = parseEmails(value);

      // Update the mail data
      newMailData[name] = emails;

      // Check for invalid emails in the updated field
      if (checkForInvalidEmails(emails)) {
        newPageError[name] =
          "Only emails with @careconnectmd.com domain are allowed";
        disableButton = true;
      } else {
        newPageError[name] = "";
      }
    } else {
      newMailData[name] = value;
    }

    // Check for invalid emails in both 'To' and 'CC' fields
    const hasInvalidEmails =
      checkForInvalidEmails(newMailData.sendMailto || []) ||
      checkForInvalidEmails(newMailData.sendMailcc || []);

    // Set the disable button state
    setDisable(hasInvalidEmails || disableButton);

    // Update the state with the new values
    setMailData(newMailData);
    setPageError(newPageError);
  };

  const onSubmit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let errors = {};

    if (!mailData?.sendMailto?.length) {
      errors.sendMailto = "At least one email address is required";
    } else {
      for (let email of mailData?.sendMailto) {
        if (!emailRegex.test(email)) {
          errors.sendMailto = "Invalid email format";
          break;
        }
      }
    }

    // Filter out any empty CC values
    const filteredCC = mailData?.sendMailcc?.filter((cc) => cc.trim() !== "");

    if (filteredCC?.length > 1) {
      for (let email of filteredCC) {
        if (!emailRegex.test(email)) {
          errors.sendMailcc = "Invalid email format";
          break;
        }
      }
    }

    setPageError(errors);

    if (Object.keys(errors)?.length === 0) {
      const htmlMessageBody = convertEditorContentToHTML();

      const finalMailData = {
        ...mailData,
        sendMailcc: filteredCC, // Use filtered CC values
        messageBody: htmlMessageBody,
      };

      setDisable(true);

      sendEmail(finalMailData)
        .then(() => {
          openNotification("success", "Success", "Email Sent Successfully!");

          close();
          setDisable(false);
        })
        .catch((error) => {
          openNotification("error", "Error", "Email Failed to Send");
          setDisable(false);
        });
    }
  };

  const convertEditorContentToHTML = () => {
    const contentState = editorState.getCurrentContent();
    let rawContent = convertToHTML(contentState);

    const phoneRegex = /\+\d{1}\(\d{3}\)-\d{3}-\d{4}/g;
    rawContent = rawContent.replace(phoneRegex, (match) => {
      return `<a href="tel:${match}">${match}</a>`;
    });

    const urlRegex = /((http|https):\/\/[^\s,<>"]+|www\.[^\s,<>"]+)/g;

    let formattedContent = rawContent.replace(urlRegex, (match) => {
      const formattedURL = match.startsWith("www.")
        ? `https://${match}`
        : match;
      return `<a href="${formattedURL}" target="_blank" rel="noopener noreferrer">${match}</a>`;
    });

    formattedContent = formattedContent
      .replace(/<\/a>,<\/a>/g, "</a><br/><a>")
      .replace(/<\/a>,/g, "</a><br/>")
      .replace(/<\/a><br\/>$/g, "</a>");

    return formattedContent;
  };

  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (modalTitle === "Tuck In Call") {
      setColumns(TuckInCallColumns);
    } else if (modalTitle === "Therapy Utilization Review") {
      setColumns(TherapyColumns);
    } else if (modalTitle === "Change in Condition Note") {
      setColumns(ChangeinConditionColumns);
    } else if (modalTitle.includes("General Notes")) {
      setColumns(NotesColumns);
    } else if (modalTitle === "Inpatient Hospital Communication Note") {
      setColumns(IPHospitalCommColumns);
    } else if (modalTitle === "Admission / Re-Admission") {
      setColumns(AdmReAdmColumns);
    } else if (modalTitle === "High Risk Assessment") {
      setColumns(HighRiskColumns);
    } else if (modalTitle === "Grievance Template") {
      setColumns(GrievanceColumns);
    } else if (modalTitle === "ED Visit Template") {
      setColumns(EDColumns);
    } else if (modalTitle === "Request for Tier Change") {
      setColumns(TierChangeColumns);
    } else if (modalTitle === "Order / Request Template") {
      setColumns(OrderColumns);
    } else if (modalTitle === "DME Request") {
      setColumns(DMEColumns);
    } else if (modalTitle === "Nurse Request Template") {
      setColumns(NurseColumns);
    } else if (modalTitle === "Hospital Admission Template") {
      setColumns(HospAdmColumns);
    } else if (modalTitle === "Hospital Discharge Template") {
      setColumns(HospDischColumns);
    } else if (modalTitle === "Outbound Call") {
      setColumns(OutboundColumns);
    } else if (modalTitle === "Triage Call") {
      setColumns(TriageColumns);
    }
  }, [modalTitle]);

  const formatSpaces = (callType) => {
    return callType.replace(/_/g, " ");
  };

  const formatMedicareAB = ({ medicareA, medicareB }) => {
    let elements = "";

    if (medicareA === "Yes") {
      elements += "Medicare A";
    }

    if (medicareB === "Yes") {
      if (elements) {
        elements += ", ";
      }
      elements += "Medicare B";
    }

    return elements || "";
  };

  const formatReviewDateAndCreatedBy = ({ reviewDate, createdBy }) => {
    const [name] = createdBy?.split("@") || [""];

    // Ensure the review date is valid, or handle "NaT" and nullish values
    const date =
      reviewDate && dayjs(reviewDate).isValid() && reviewDate !== "NaT"
        ? dayjs(reviewDate).format("YYYY-MM-DD hh:mm A")
        : "";

    // Check if the date is an invalid placeholder like "1900-01-01 12:00 AM" or "NaT"
    return date === "1900-01-01 12:00 AM" || date === "NaT"
      ? `${name}`
      : `${date}\n${name}`;
  };

  const formatReviewDate = ({ reviewDate, createdBy }) => {
    const date = reviewDate ? dayjs(reviewDate).format("YYYY-MM-DD") : "";
    const [name] = createdBy?.split("@") || [""];
    return date === "1900-01-01"
      ? `${name}`
      : name
      ? `${date}\n${name}`
      : `${date}`;
  };

  const formatContactInfo = (name, phoneNumber) => {
    const number = formatPhoneNumber(phoneNumber);
    return number ? `${name}\n${number}` : name;
  };

  const formatDateOfBirth = (patient) => {
    return patient?.label.split(" - ")[1] || "";
  };

  const formatCCECompletedThisYear = (isCCECompletedThisYear) => {
    return isCCECompletedThisYear === "Yes"
      ? "Yes"
      : isCCECompletedThisYear === "No"
      ? "No"
      : "Not found in EMR";
  };

  const formatPhoneNumberwithExtn = (phoneNumber, extension) => {
    const number = formatPhoneNumber(phoneNumber);
    return `${number}\n${number && extension ? `Ext. ${extension}` : ""}`;
  };

  const formatLabelWithOther = (label, other) => {
    return `${label || ""}\n${label === "Other" ? other || "" : ""}`;
  };

  const createdDT = ({ reviewDate, createdBy }) => {
    const [name] = createdBy?.split("@") || [""];
    const date = reviewDate ? utcToLocal(reviewDate) : "";
    return date === "1900-01-01 12:00 AM" ? `${name}` : `${date}\n${name}`;
  };

  const renderData = (column, data) => {
    const iData = data[column.dataIndex];

    switch (column?.dataIndex) {
      case "dateOfOrderSubmoission":
      case "dateRequestSent":
        return iData
          ? formatReviewDate({ reviewDate: iData, createdBy: "" })
          : "";
      case "reviewDate":
        return iData
          ? formatReviewDate({ reviewDate: iData, createdBy: data?.createdBy })
          : "";
      case "callType":
        return iData ? formatSpaces(iData) : "";
      case "medicareA":
        return data ? formatMedicareAB(data) : "";
      case "createdDateTime":
        return data
          ? createdDT({
              reviewDate: iData,
              createdBy: data?.createdBy,
            })
          : "";
      case "followupDateTime":
      case "noteDateTime":
      case "iPCommunicationNoteDateTime":
      case "assessmentDateTime":
      case "dateTimeCreated":
        return data
          ? formatReviewDateAndCreatedBy({
              reviewDate: iData,
              createdBy: data?.createdBy,
            })
          : "";
      case "providerResponseDateTime":
        return data
          ? formatReviewDateAndCreatedBy({
              reviewDate: iData,
              createdBy: "",
            })
          : "";
      case "callBackNumber":
        return data
          ? formatPhoneNumberwithExtn(
              data.callBackNumber,
              data.callBackNumberExt
            )
          : "";
      case "phoneNumbers":
        return data ? formatPhoneNumber(data?.phoneNumbers) : "";
      case "attendingPhysician":
        return data
          ? formatContactInfo(
              data?.attendingPhysician,
              data?.attendingPhysicianPhoneNumber
            )
          : "";
      case "hospitalCareManager":
        return data
          ? formatContactInfo(
              data?.hospitalCareManager,
              data?.hospitalCareManagerPhoneNumber
            )
          : "";
      case "erName":
        return data ? formatContactInfo(data?.erName, data?.erTelephone) : "";
      case "hospitalAttendingMDName":
        return data
          ? formatContactInfo(
              data?.hospitalAttendingMDName,
              data?.hospitalAttendingMDContact
            )
          : "";
      case "hospitalCaseManagerName":
        return data
          ? formatContactInfo(
              data?.hospitalCaseManagerName,
              data?.hospitalCaseManagerContact
            )
          : "";
      case "callerName":
        return data
          ? formatContactInfo(data?.callerName, data?.callerContact)
          : "";
      case "hospitalName":
        return data
          ? formatContactInfo(data?.hospitalName, data?.hospitalTelephone)
          : "";
      case "dateOfBirth":
        return data ? formatDateOfBirth(data?.patient) : "";
      case "isCCECompletedThisYear":
        return data
          ? formatCCECompletedThisYear(data?.isCCECompletedThisYear)
          : "";
      case "nporpaLabel":
        return data
          ? formatLabelWithOther(data?.nporpaLabel, data?.nporpaOther)
          : "";
      case "providerNameLabel":
        return data
          ? formatLabelWithOther(
              data?.providerNameLabel,
              data?.providerNameOther
            )
          : "";
      case "createdBy":
        if (
          (modalTitle === "Grievance Template" ||
            modalTitle === "ED Visit Template" ||
            modalTitle === "Request for Tier Change" ||
            modalTitle === "Order / Request Template" ||
            modalTitle === "DME Request" ||
            modalTitle === "Nurse Request Template" ||
            modalTitle === "Hospital Admission Template" ||
            modalTitle === "Hospital Discharge Template" ||
            modalTitle === "Outbound Call") &&
          data?.createdBy
        ) {
          const [name] = data.createdBy.split("@");
          return name;
        } else {
          return data?.createdBy || "";
        }
      case "isThereNewMedication":
        return iData === "Yes"
          ? `Yes\n${data?.newMedicationNotes}`
          : iData === "Unk"
          ? "Unknown"
          : "No";
      case "codeStatusOnFile":
        return iData === "Unk" ? "Unknown" : iData;
      case "isMedicationArrived":
        return iData === "No"
          ? `No\n${data?.medicationDeliveryNotes}`
          : iData === "Unk"
          ? "Unknown"
          : "Yes";
      case "isNCPFollowUpCallSetUpDone":
        return iData === "No" ? `No\n${data?.ncpFollowUpCallNotes}` : "Yes";
      case "location":
        return iData === "Other"
          ? `${iData}\n${data?.locationOtherNotes}`
          : `${iData}`;
      default:
        return iData || "";
    }
  };

  useEffect(() => {
    if (editRecord && columns?.length > 0) {
      const formattedData = columns?.map((column) => {
        const title = column?.title;
        const data = renderData(column, editRecord);
        return { title, data };
      });

      let formattedPatientName;

      if (patientName && patientName?.includes("Complete RCA")) {
        formattedPatientName = patientName.replace("- (Complete RCA)", "");
      } else {
        formattedPatientName = patientName;
      }

      const blocks = [
        {
          text: "Patient Name",
          type: "unstyled",
          inlineStyleRanges: [
            {
              offset: 0,
              length: "Patient Name"?.length,
              style: "BOLD",
            },
          ],
        },
        {
          text: formattedPatientName || "",
          type: "unstyled",
        },
        {
          text: "",
          type: "unstyled",
        },
        ...formattedData?.flatMap(({ title, data }) => [
          {
            text: title,
            type: "unstyled",
            inlineStyleRanges: [
              {
                offset: 0,
                length: title?.length,
                style: "BOLD",
              },
            ],
          },
          {
            text: data,
            type: "unstyled",
          },
          {
            text: "",
            type: "unstyled",
          },
        ]),
        {
          text: "CONFIDENTIALITY NOTICE: This e-mail, and any attachment to it may contain confidential information intended only for the use of the individual(s) or entity named on the e-mail. This transmission may also contain information that is protected and governed by the Health Insurance Portability and Accountability Act (HIPAA). If you are not the intended recipient, be advised that you have received this email in error and that any use, dissemination, forwarding, or copying of this email is strictly prohibited. If you have received this email in error, please immediately return it to the sender and delete it from your system.",
          type: "unstyled",
        },
      ];

      const contentState = convertFromRaw({
        entityMap: {},
        blocks: blocks,
      });

      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [editRecord, columns, patientName]);

  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  const handleFormat = (style) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, style));
  };

  return (
    <Drawer open={open} onClose={close} width={900} title={"Send Email"}>
      <div className="tw-space-y-4">
        <div>
          <div className="tw-font-semibold tw-text-xl">From</div>
          <div className="tw-text-base">{fromName}</div>
        </div>
        <div>
          <div className="tw-font-semibold tw-text-xl">To</div>
          <div className="tw-text-base">
            <input
              maxLength={200}
              className="form-control "
              type="text"
              id="to"
              value={mailData?.sendMailto.join(", ")}
              onChange={(e) => handleInputChange("sendMailto", e.target.value)}
              style={{
                border: pageError?.sendMailto ? "1px solid red" : "",
              }}
            />
            {pageError.sendMailto && (
              <div className="tw-text-red-500">{pageError.sendMailto}</div>
            )}
          </div>
        </div>
        <div>
          <div className="tw-font-semibold tw-text-xl">CC</div>
          <div className="tw-text-base">
            <input
              maxLength={200}
              className="form-control "
              type="text"
              id="to"
              value={mailData?.sendMailcc.join(", ")}
              onChange={(e) => handleInputChange("sendMailcc", e.target.value)}
              style={{
                border: pageError?.sendMailcc ? "1px solid red" : "",
              }}
            />
            {pageError.sendMailcc && (
              <div className="tw-text-red-500">{pageError.sendMailcc}</div>
            )}
          </div>
        </div>
        <div>
          <div className="tw-font-semibold tw-text-xl">Subject</div>
          <div className="tw-text-base">
            <input
              maxLength={200}
              className="form-control"
              type="text"
              id="subject"
              value={mailData.subject}
              onChange={(e) => handleInputChange("subject", e.target.value)}
            />
          </div>
        </div>
        <div>
          <div className="tw-font-semibold tw-text-xl">Body</div>

          <div className="border border-gray-300 rounded-md p-2">
            <Editor
              editorState={editorState}
              onChange={handleEditorChange}
              placeholder="Write your email content here..."
              readOnly
            />
          </div>
          {/* <div className="tw-space-x-2 tw-mt-2">
            <button
              onClick={() => handleFormat("BOLD")}
              className="btn tw-bg-gray-200"
            >
              B
            </button>
            <button
              onClick={() => handleFormat("ITALIC")}
              className="btn tw-bg-gray-200 "
            >
              I
            </button>
            <button
              onClick={() => handleFormat("UNDERLINE")}
              className="btn tw-bg-gray-200"
            >
              U
            </button>
          </div> */}

          {/* <div className="border border-gray-300 rounded-md p-2">
            <Editor
              editorState={editorState}
              onChange={handleEditorChange}
              placeholder="Write your email content here..."
            />
          </div> */}
        </div>
      </div>
      <div className="form-group tw-fixed tw-top-2 tw-right-10">
        <button
          className={"btn btn-success"}
          onClick={onSubmit}
          disabled={disable}
        >
          Send
        </button>
        <button className="btn btn-secondary tw-ml-4" onClick={close}>
          Discard
        </button>
      </div>
    </Drawer>
  );
};

export default SendEmail;
