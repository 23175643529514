import axios from "axios";
import * as Settings from "../GlobalConfiguration";
import { tokenRequest } from "../components/authConfig";
import { msalInstance } from "..";

//const token = sessionStorage.getItem("Bearer");
export const axiosInstance = axios.create({
  baseURL: Settings.Api_Url,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    let accessToken;
    await msalInstance.initialize(); // Ensure initialization if applicable.
    if (msalInstance.getActiveAccount()) {
      const response = await msalInstance.acquireTokenSilent(tokenRequest);
      accessToken = response.accessToken;
    }

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      accessToken = sessionStorage.getItem("Bearer");
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    if (config.method === "post") {
      config.data = JSON.stringify(config.data); // Convert request data to JSON string
    }
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

export const axiosInstancepy = axios.create({
  baseURL:
    Settings.MODE === "PRODUCTION"
      ? Settings.cccgate_patient
      : Settings.cccgate_qatpatient,
  headers: {
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": Settings.subscriptionKey,
  },
});

axiosInstancepy.interceptors.request.use(
  async (config) => {
    let accessToken;
    await msalInstance.initialize(); // Ensure initialization if applicable.
    if (msalInstance.getActiveAccount()) {
      const response = await msalInstance.acquireTokenSilent(tokenRequest);
      accessToken = response.accessToken;
    }

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      accessToken = sessionStorage.getItem("Bearer");
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    if (config.method === "post") {
      config.data = JSON.stringify(config.data); // Convert request data to JSON string
    }
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);


export const axiosInstancehn = axios.create({
  baseURL:
    Settings.MODE === "PRODUCTION"
      ? Settings.cccgate_careevent
      : Settings.cccgate_qacareevent,
  headers: {
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": Settings.subscriptionKey,
  },
});

axiosInstancehn.interceptors.request.use(
  async (config) => {
    let accessToken;
    await msalInstance.initialize(); // Ensure initialization if applicable.
    if (msalInstance.getActiveAccount()) {
      const response = await msalInstance.acquireTokenSilent(tokenRequest);
      accessToken = response.accessToken;
    }

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      accessToken = sessionStorage.getItem("Bearer");
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    if (config.method === "post") {
      config.data = JSON.stringify(config.data); // Convert request data to JSON string
    }
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);
