import { axiosInstancehn } from "./AxiosInstance";

export async function postVisitTracking(body) {
  return axiosInstancehn
    .post("/AddCceRequest", body)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}

export async function deleteVisitTracking(body) {
  return axiosInstancehn
    .delete("/DeleteCceRequest", { data: body })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
}
