import React from "react";
import { fetcherhn } from "../../services/fectcher";
import useSWR from "swr";
import { FaHospitalUser, FaRegUser } from "react-icons/fa";
import { RiCake2Line } from "react-icons/ri";
import { Skeleton } from "antd";
import { getPatientSourceBadgeColors } from "../../components/Pt360/Pt360";

const PatientBanner = ({ patientData }) => {
  const { data: bannerData, isLoading: bannerDataLoading } = useSWR(
    `GetPatientBannerData?mbi=${patientData?.mbi}`,
    fetcherhn
  );

  const { data: rafData } = useSWR(
    `getRecalutedraf?mbi=${patientData?.mbi}&dob=${patientData?.dateOfBirth}&gender=${patientData?.gender}`,
    fetcherhn
  );

  const bannerDataFlat = { ...bannerData?.[0] };
  const rafDataFlat = { ...rafData?.[0] };
  return (
    <div className="tw-text-gray-700 border tw-rounded-md tw-flex">
      <div className="tw-w-1/5 tw-font-medium tw-bg-gray-100 tw-px-4 tw-py-2 tw-rounded-l-[5px]">
        <div className="tw-capitalize tw-text-2xl tw-text-gray-800">
          {patientData?.patientName}
        </div>
        <div className="tw-flex tw-items-center tw-space-x-2">
          <div>{patientData?.mbi}</div>

          <div
            className={`tw-py-0.5 tw-px-2 tw-text-xs tw-rounded  ${getPatientSourceBadgeColors(
              `${patientData?.lob}`
            )}`}
          >
            {patientData?.lob}
          </div>
        </div>
        <div className="tw-mt-2">
          <div className="tw-flex tw-items-center tw-space-x-2">
            <FaRegUser size={18} className="tw-text-gray-400" />
            <div>{patientData?.gender || "NA"}</div>
          </div>
          <div className="tw-mt-2 tw-flex tw-items-center tw-space-x-2">
            <RiCake2Line size={18} className="tw-text-gray-400" />
            <div>{patientData?.dateOfBirth || "NA"}</div>
          </div>
          <div className="tw-ml-[26px]">{patientData?.age || "NA"} years</div>
          <div className="tw-mt-2 tw-flex tw-items-center tw-space-x-2">
            <div className="tw-mt-2 tw-flex tw-items-center tw-space-x-2">
              <FaHospitalUser size={18} className="tw-text-gray-400" />
              <div>{bannerDataFlat?.alignmentStatusLatestMonth || "NA"}</div>
            </div>
          </div>
          <div className="tw-mt-2">
            <p className="tw-mb-0 tw-pb-0">
              Deceased Date -{" "}
              {bannerDataFlat?.beneficiaryDateofDeath !== "1900-01-01"
                ? bannerDataFlat?.beneficiaryDateofDeath
                : "NA"}
            </p>
          </div>
        </div>
      </div>

      <div className="tw-w-4/5 tw-py-2 tw-px-4">
        {bannerDataLoading ? (
          <Skeleton active={bannerDataLoading} className="tw-mt-10" />
        ) : (
          <div className="tw-grid tw-grid-cols-2 tw-gap-x-8 tw-gap-y-2">
            {[
              {
                label: "CCMD Provider",
                value: patientData?.ccmdProvider || "NA",
              },
              {
                label: `CCMD RAF`,
                value: rafDataFlat?.ccmdRAF || "0",
              },
              {
                label: "CCMD Provider Group",
                value: patientData?.providerGroup || "NA",
              },
              {
                label: `CMS RAF${
                  rafDataFlat?.rafYear ? ` - ${rafDataFlat.rafYear}` : ""
                }`,
                value: rafDataFlat?.cmsRAF || "0",
              },
              {
                label: "CMS Provider",
                value: patientData?.alignedProvider || "NA",
              },
              {
                label: "First Alignment Date",
                value:
                  bannerDataFlat?.beneFirstAlignDate === "1900-01-01" ||
                  !bannerDataFlat?.beneFirstAlignDate
                    ? "NA"
                    : bannerDataFlat.beneFirstAlignDate,
              },
              {
                label: "CMS Provider Group",
                value: patientData?.cmsAllignedProviderGroup || "NA",
              },
              {
                label: "Current Alignment Date",
                value:
                  bannerDataFlat?.effectiveStartDate === "1900-01-01" ||
                  !bannerDataFlat?.effectiveStartDate
                    ? "NA"
                    : bannerDataFlat.effectiveStartDate,
              },
              {
                label: "SNF Name",
                value: bannerDataFlat?.SnfFaciltyName || "NA",
              },
              {
                label: "VA Provider TIN",
                value: bannerDataFlat?.RecentVATIN || "NA",
              },
              {
                label: "SNF Location",
                value:
                  bannerDataFlat?.SnfAddress || bannerDataFlat?.SnfAddrCity
                    ? bannerDataFlat?.SnfAddress +
                      ", " +
                      bannerDataFlat?.SnfAddrCity
                    : "NA",
              },
              {
                label: "VA Provider NPI",
                value: bannerDataFlat?.RecentVANPI || "NA",
              },
              {
                label: "Region",
                value: bannerDataFlat?.regionAbbreviation || "NA",
              },

              {
                label: "VA Signature Date",
                value:
                  bannerDataFlat?.RecentVASignatureDate === "1900-01-01" ||
                  !bannerDataFlat?.RecentVASignatureDate
                    ? "NA"
                    : bannerDataFlat.RecentVASignatureDate,
              },
            ].map((item, index) => (
              <div
                key={index}
                className="tw-flex tw-justify-between tw-items-start tw-space-x-4"
              >
                <div className="tw-text-gray-500 tw-whitespace-nowrap">
                  {item.label}
                </div>
                <div className="tw-font-medium tw-text-right ">
                  {item.value}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default PatientBanner;
