import React, { useState } from "react";
import { fetcherhn } from "../../services/fectcher";
import { useLocation, useNavigate } from "react-router-dom";
import useSWR from "swr";
import {
  Button,
  Drawer,
  Popconfirm,
  Skeleton,
  Space,
  Table,
  Tooltip,
} from "antd";
import { IoArrowBackCircle } from "react-icons/io5";
import Search from "antd/es/transfer/search";

import { FaEdit, FaRegTrashAlt } from "react-icons/fa";

import { WarningOutlined } from "@ant-design/icons";
import CoderReviewForm from "./CoderReviewForm";
import useSWRImmutable from "swr/immutable";
import { usePickList } from "../../services/queries";
import dayjs from "dayjs";
import { deleteCoderReview } from "../../api/CoderReviewApi";
import { openNotification } from "../../common/elements/myNotification";
import PatientBanner from "../../common/elements/PatientBanner";

const CoderReviewPatient = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { patientData } = location.state || {};

  const initialReqState = {
    key: "",
    guid: "",
    mbi: patientData?.mbi,
    icd10Code: "",
    validationStatusLabel: "",
    validationStatusValue: "",
    noteTypeLabel: "",
    noteTypeValue: "",
    renderProvider: "",
    dateOfService: "",
    createdBy: sessionStorage?.getItem("userName") || "",
    modifiedBy: "",
  };

  const { data: icd10CodesData } = useSWRImmutable(`geticd10codes`, fetcherhn);

  const {
    data: rafData,
    isLoading: rafDataLoading,
    mutate: refetchRafData,
  } = useSWR(
    `getRecalutedraf?mbi=${patientData?.mbi}&dob=${patientData?.dateOfBirth}&gender=${patientData?.gender}`,
    fetcherhn
  );

  const { data, mutate, isLoading } = useSWR(
    `getCodeDetailsforMBI?id=${patientData?.mbi}`,
    fetcherhn
  );

  const { data: provList } = useSWRImmutable(
    `GetNpPaList?nppa_for=CODERWORKFLOW`,
    fetcherhn
  );

  const rafDataFlat = { ...rafData?.[0] };

  const validationStatusOptions = usePickList(53)?.data;
  const noteTypeOptions = usePickList(54)?.data;

  const [editRecord, setEditRecord] = useState(initialReqState);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);

  const formattedIcd10Codes = icd10CodesData?.map((icd) => ({
    value: icd.code,
    label: `${icd.code} - ${icd.icdDescription}`,
  }));

  const handleCancel = () => {
    setIsModalOpen(false);
    setEditRecord(initialReqState);
  };

  const handleOk = async (e) => {
    if (e) {
      mutate();
    }
    setIsModalOpen(false);
    setEditRecord(initialReqState);
  };

  const edit = (record) => {
    setIsModalOpen(true);
    setEditRecord(record);
  };

  const handleDelete = async (record) => {
    const body = { ...record, modifiedBy: sessionStorage.getItem("userName") };
    const deleteResult = await deleteCoderReview(body);
    if (deleteResult.status) {
      mutate();
      openNotification(
        "success",
        "Deleted",
        "Coder Review Deleted Successfully!"
      );
    }
  };

  const providerMap = provList?.reduce((acc, provider) => {
    acc[provider.value] = provider.label;
    return acc;
  }, {});

  const columns = [
    {
      title: "ICD-10 Details",
      dataIndex: "icd10Code",
      render: (text, record) => {
        return (
          <>
            <div>
              {text} - {record?.icdDescription}
            </div>
          </>
        );
      },
    },
    {
      title: "HCC Details",
      dataIndex: "hccCode",
      render: (text, record) => {
        return (
          <>
            <div>
              {text} - {record?.hccDescription}
            </div>
          </>
        );
      },
    },
    {
      title: "Relative Factor",
      dataIndex: "relativeFactor",
      width: 90,
    },
    {
      title: "Validation Status",
      dataIndex: "validationStatusLabel",
      width: 120,
    },
    {
      title: "Note Type",
      dataIndex: "noteTypeLabel",
    },
    {
      title: "Rendering Provider",
      dataIndex: "renderProvider",
      render: (value) => providerMap?.[value] || value,
    },

    {
      title: "Date of Service",
      dataIndex: "dateOfService",
      width: 110,
      render: (text) => {
        if (text && dayjs(text).isValid()) {
          const formattedDate = dayjs(text).format("YYYY-MM-DD");
          return formattedDate === "1900-01-01" ? "" : formattedDate;
        }
        return "";
      },
    },
    {
      key: "key",
      title: "Operation",
      dataIndex: "guid",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <div>
          <Space>
            <Tooltip title="Edit">
              <FaEdit
                size={20}
                className=" tw-cursor-pointer"
                onClick={() => edit(record)}
                color="blue"
              />
            </Tooltip>
            <Tooltip title="Delete">
              <Popconfirm
                title="Ok to Delete?"
                onConfirm={() => handleDelete(record)}
                icon={
                  <WarningOutlined
                    style={{
                      color: "red",
                    }}
                  />
                }
              >
                <FaRegTrashAlt
                  size={20}
                  className=" tw-cursor-pointer"
                  color="red"
                />
              </Popconfirm>
            </Tooltip>
          </Space>
        </div>
      ),
    },
  ];

  const finalResult = data
    ? data?.filter((item) => {
        return [
          "icd10Code",
          "icdDescription",
          "hccCode",
          "hccDescription",
          "relativeFactor",
          "validationStatusLabel",
          "noteTypeLabel",
          "renderProvider",
          "dateOfService",
        ].some((key) => {
          let fieldValue = "";

          // Check if the field is 'renderProvider' to map the value to its label
          if (key === "renderProvider" && providerMap) {
            fieldValue = providerMap[item[key]]?.toLowerCase(); // Convert label to lowercase for search
          } else {
            fieldValue = item[key]
              ?.toString()
              ?.toLowerCase()
              .replace(/_/g, " ");
          }

          return fieldValue?.includes(
            query?.toLowerCase().replace(/_/g, " ") ?? ""
          );
        });
      })
    : data;

  const handleRecalculate = () => {
    refetchRafData();
  };

  const handlePageChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  };

  return (
    <>
      <div className="tw-sticky tw-top-0 tw-z-50 tw-bg-white">
        <div className="tw-flex tw-justify-between">
          <Space>
            <IoArrowBackCircle
              className="tw-cursor-pointer"
              size="2em"
              onClick={() =>
                navigate("/CoderReview", {
                  state: {
                    query: location?.state?.query,
                    page: location?.state?.page,
                    provider: location?.state?.provider,
                    cmsProvider: location?.state?.cmsProvider,
                    status: location?.state?.status,
                    pageSize: location?.state?.pageSize,
                  },
                })
              }
            />
            <p
              className="pt-3 tw-text-xl"
              style={{
                color: "#D2691E",
                fontWeight: "600",
              }}
            >
              Coder Review for {patientData?.patientName}
            </p>
          </Space>

          <div className="tw-flex tw-justify-end tw-mt-3">
            <Space>
              <Search
                className=" tw-mb-4"
                size="middle"
                placeholder="Search"
                onChange={(e) => setQuery(e.target.value)}
                allowClear
              />
              <Button
                type="primary"
                onClick={() => setIsModalOpen(true)}
                className=""
              >
                Add
              </Button>
            </Space>
          </div>
        </div>
        <PatientBanner patientData={patientData} />
        {rafDataLoading ? (
          <Skeleton active={rafDataLoading} />
        ) : (
          <div className="tw-flex tw-w-full tw-my-3 tw-space-x-4 tw-p-4 tw-bg-gray-100 tw-rounded-md">
            <div className="tw-bg-white tw-flex tw-flex-col tw-items-center tw-justify-center tw-rounded-md tw-flex-1">
              <div className="tw-p-1 tw-font-semibold">CCE Impact RAF</div>
              <div className="tw-p-1 tw-flex tw-items-center tw-justify-center">
                {rafDataFlat?.cceImpactRaf}
              </div>
            </div>
            <div className="tw-bg-white tw-flex tw-flex-col tw-items-center tw-justify-center tw-rounded-md tw-flex-1">
              <div className="tw-p-1 tw-font-semibold">Total Count of Adds</div>
              <div className="tw-p-1 tw-flex tw-items-center tw-justify-center">
                {rafDataFlat?.totalAdd}
              </div>
            </div>
            <div className="tw-bg-white tw-flex tw-flex-col tw-items-center tw-justify-center tw-rounded-md tw-flex-1">
              <div className="tw-p-1 tw-font-semibold">Recapture Rate</div>
              <div className="tw-p-1 tw-flex tw-items-center tw-justify-center">
                {rafDataFlat?.recaptureRate
                  ? `${rafDataFlat?.recaptureRate} %`
                  : 0}
              </div>
            </div>
            <div
              className="tw-bg-blue-500 tw-text-white tw-flex tw-items-center tw-justify-center tw-font-semibold tw-rounded-md tw-cursor-pointer tw-flex-1"
              onClick={handleRecalculate}
            >
              Re-Calculate
            </div>
          </div>
        )}
      </div>

      {isModalOpen && (
        <Drawer
          open={isModalOpen}
          title="Add/Update Coder Review"
          onClose={handleCancel}
          width={600}
        >
          <CoderReviewForm
            icd10Codes={formattedIcd10Codes}
            validationStatusOptions={validationStatusOptions}
            noteTypeOptions={noteTypeOptions}
            editRecord={editRecord}
            callback={handleOk}
            provList={provList}
          />
        </Drawer>
      )}
      {isLoading ? (
        <Skeleton active={isLoading} />
      ) : (
        <Table
          dataSource={finalResult}
          columns={columns}
          virtual
          bordered
          pagination={{
            current: page,
            pageSize: pageSize,
            onChange: (page, pageSize) => handlePageChange(page, pageSize),
            showTotal: (total, [from, to]) =>
              `Showing ${from} - ${to} of ${total} items`,
          }}
        />
      )}
    </>
  );
};

export default CoderReviewPatient;
