const clientId = "07d0facd-f211-47c1-b103-2dc2e9e032d0";
const authority =
  "https://login.microsoftonline.com/54f55ff8-42e3-48db-a263-aa717e16fe12";

export const msalConfig = {
  auth: {
    clientId: clientId,
    authority: authority,
    redirectUri: "https://ccc.careconnectmd.com/",
    postLogoutRedirectUri: "https://ccc.careconnectmd.com/", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const msalConfigqa = {
  auth: {
    clientId: clientId,
    authority: authority,
    redirectUri: "https://qa-ccc.careconnectmd.com",
    postLogoutRedirectUri: "https://qa-ccc.careconnectmd.com", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const msalConfigdev = {
  auth: {
    clientId: clientId,
    authority: authority,
    redirectUri: "https://localhost:1991",
    postLogoutRedirectUri: "https://localhost:1991", // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const loginRequest = {
  scopes: [],
};

export const tokenRequest = {
  scopes: ["api://bb0898b0-ee7a-41f9-b322-f1776a30c458/WebAPI"],
};
/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
// export const silentRequest = {
//   scopes: ["openid", "profile"],
//   loginHint: "example@domain.net",
// };
