import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useNavigationType } from "react-router-dom";
import { Table, Select } from "antd";
import Search from "antd/es/input/Search";
import PageTitle from "../Common/PageTitle";
import { formatOptionLabel } from "../../common/elements/myCustomSelect";
import useSWRImmutable from "swr/immutable";
import { fetcher, fetcherhn } from "../../services/fectcher";
import useSWR from "swr";
import dayjs from "dayjs";
import { FaCalendarAlt } from "react-icons/fa";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";

const PatientStatus = [
  {
    value: "All",
    label: "All",
  },
  {
    value: "Active",
    label: "Active",
  },
  {
    value: "Deceased",
    label: "Deceased",
  },
  {
    value: "Dealigned",
    label: "Dealigned",
  },
];

const VisitTrackingHome = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const navigateType = useNavigationType();

  const [provider, setProvider] = useState("");
  const [cmsProvider, setCMSProvider] = useState("");
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [status, setStatus] = useState("Active");

  const { data: tableData, isLoading: tableDataLoading } = useSWR(
    `getPatientCceRecords?providerGroup=All&Provider=${
      provider || "All"
    }&allignedProvider=${
      cmsProvider || "All"
    }&cmsProvider=All&patientStatus=${status}`,
    fetcherhn
  );

  const { data: provList } = useSWRImmutable(
    `getccmdproviders?lob=HN`,
    fetcher
  );
  const { data: cmsProvList } = useSWRImmutable(`getAlignedProvider`, fetcher);

  const onProviderChange = (e) => {
    setProvider(e);
  };

  const onCMSProviderChange = (e) => {
    setCMSProvider(e);
  };

  useEffect(() => {
    if (location.state?.query) {
      setQuery(location.state.query);
    }
    if (location.state?.page) {
      setPage(location.state.page);
    }
    if (location.state?.pageSize) {
      setPageSize(location.state.pageSize);
    }

    if (location.state?.provider) {
      const providerOption = provList?.find(
        (option) => option.label === location.state.provider
      );
      setProvider(providerOption ? providerOption.value : null);
    } else {
      setProvider(null);
    }
    if (location.state?.cmsProvider) {
      const cmsProviderOption = cmsProvList?.find(
        (option) => option.label === location.state.cmsProvider
      );
      setCMSProvider(cmsProviderOption ? cmsProviderOption.value : null);
    } else {
      setCMSProvider(null);
    }

    if (location.state?.status) {
      const statusOption = PatientStatus?.find(
        (option) => option.label === location.state.status
      );
      setStatus(statusOption ? statusOption.value : null);
    } else {
      setStatus("Active");
    }

    if (navigateType === "POP") {
      setStatus("Active");

      setQuery("");
      setPage(1);
      setPageSize(10);
    }
  }, [location.state, navigateType]);

  const columns = [
    {
      title: "Patient Name",
      dataIndex: "patientNamewithDOB",
      width: 320,
      render: (text, record) => (
        <>
          <div className="tw-font-medium">
            {formatOptionLabel({ label: record?.patientNamewithDOB })}
          </div>
          <div className="tw-font-medium tw-text-gray-600">{record?.mbi}</div>
          {record.gender === "Male" ? (
            <p className="tw-font-medium tw-mb-0 tw-text-gray-500">
              {record.age} / M
            </p>
          ) : (
            <p className="tw-font-medium tw-mb-0 tw-text-gray-500">
              {record.age} / F
            </p>
          )}
        </>
      ),
    },
    {
      title: "CCMD Provider / Group",
      dataIndex: "ccmdProvider",
      width: 200,
      render: (text, record) => (
        <>
          <div>{record.ccmdProvider}</div>
          <div className="tw-text-gray-600">{record.providerGroup}</div>
        </>
      ),
    },
    {
      title: "CMS Provider / Group",
      dataIndex: "alignedProvider",
      width: 200,
      render: (text, record) => (
        <>
          <div>{record.alignedProvider}</div>
          <div className="tw-text-gray-600">
            {record.cmsAllignedProviderGroup}
          </div>
        </>
      ),
    },
    // {
    //   title: "CCMD Provider",
    //   dataIndex: "ccmdProvider",
    // },
    // {
    //   title: "CCMD Provider Group",
    //   dataIndex: "providerGroup",
    // },
    // {
    //   title: "CMS Provider",
    //   dataIndex: "alignedProvider",
    // },
    // {
    //   title: "CMS Provider Group",
    //   dataIndex: "cmsAllignedProviderGroup",
    // },
    {
      title: "Visit Type",
      dataIndex: "visitTypeLabel",
      render: (text, record) => {
        return (
          <>
            <div>{text}</div>
          </>
        );
      },
    },
    {
      title: "Visit Status",
      dataIndex: "visitStatusLabel",
      width: 200,
      render: (text, record) => {
        let formattedDate = "";
        if (
          record.visitStatusScheduledDate &&
          dayjs(record.visitStatusScheduledDate).isValid()
        ) {
          const tempDate = dayjs(record.visitStatusScheduledDate).format(
            "YYYY-MM-DD hh:mm A"
          );
          formattedDate = tempDate === "1900-01-01 12:00 AM" ? "" : tempDate;
        }

        return (
          <div>
            <div>{text}</div>
            {formattedDate && (
              <div className="tw-flex tw-items-center tw-space-x-2 tw-text-gray-600">
                <FaCalendarAlt size={16} className="tw-align-middle " />
                <p className="tw-mb-0">{formattedDate}</p>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "Scheduling Details",
      dataIndex: "schedulingDetailsLabel",
    },
    {
      title: "Scheduling Detail Date",
      dataIndex: "schedulingDetailDate",
      width: 200,
      render: (text) => {
        if (text && dayjs(text).isValid()) {
          const formattedDate = dayjs(text).format("YYYY-MM-DD hh:mm A");
          return formattedDate === "1900-01-01 12:00 AM" ? "" : formattedDate;
        }
        return "";
      },
    },
    {
      title: "Notes",
      dataIndex: "notes",
    },
  ];

  const finalResult = tableData
    ? tableData
        .filter((item) => {
          return [
            "patientNamewithDOB",
            "mbi",
            "ccmdProvider",
            "providerGroup",
            "alignedProvider",
            "cmsAllignedProviderGroup",
            "visitTypeLabel",
            "visitStatusLabel",
            "visitStatusScheduledDate",
            "schedulingDetailsLabel",
            "schedulingDetailDate",
            "notes",
          ].some((key) =>
            (
              item[key]?.toString()?.toLowerCase().replace(/_/g, " ") ?? ""
            ).includes(query?.toLowerCase().replace(/_/g, " ") ?? "")
          );
        })
        .map((item) => ({
          ...item,
          key: item.mbi || item.id || `${item.patientNamewithDOB}-${item.age}`,
        }))
    : tableData;

  const handlePageChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  };

  return (
    <>
      <div className="tw-flex tw-justify-between">
        <PageTitle title="Visit Tracking" />
        <div className="tw-flex tw-space-x-2 ">
          <Select
            className="tw-w-full"
            showSearch
            allowClear
            disabled={cmsProvider ? true : false}
            placeholder="Select CCMD Provider..."
            optionFilterProp="children"
            value={provider ?? null}
            onChange={onProviderChange}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={provList}
          />

          <Select
            className="tw-w-full"
            showSearch
            allowClear
            disabled={provider ? true : false}
            placeholder="Select CMS Provider..."
            optionFilterProp="children"
            value={cmsProvider ?? null}
            onChange={onCMSProviderChange}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={cmsProvList}
          />

          <Search
            size="middle"
            placeholder="Search"
            value={query}
            allowClear
            onChange={(e) => setQuery(e.target.value)}
          />
          <Select
            className="tw-w-full"
            showSearch
            placeholder="Active"
            optionFilterProp="children"
            defaultValue={PatientStatus[1].value}
            value={status}
            onChange={(e) => setStatus(e)}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={PatientStatus}
          />
        </div>
      </div>

      {tableDataLoading ? (
        <MyBackdropSpinner show={tableDataLoading} />
      ) : (
        <Table
          columns={columns}
          scroll={{ x: 1500 }}
          dataSource={finalResult}
          pagination={{
            current: page,
            pageSize: pageSize,
            onChange: (page, pageSize) => handlePageChange(page, pageSize),
            showTotal: (total, [from, to]) =>
              `Showing ${from} - ${to} of ${total} items`,
          }}
          onRow={(record) => ({
            className: "tw-cursor-pointer",
            onClick: () => {
              navigate(`/taskmanagement/visittracking/patient/${record?.mbi}`, {
                state: {
                  patientData: record,
                  query,
                  page,
                  provider,
                  cmsProvider,
                  status,
                  pageSize,
                },
              });
            },
          })}
        />
      )}
    </>
  );
};

export default VisitTrackingHome;
