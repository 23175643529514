import { Button, Divider, message, Modal, Tooltip } from "antd";
import React, { useState, useEffect, useRef } from "react";
import { fetcherhn } from "../../services/fectcher";
import useSWR from "swr";
import {
  AdmReAdmColumns,
  ChangeinConditionColumns,
  DMEColumns,
  EDColumns,
  GrievanceColumns,
  HighRiskColumns,
  HospAdmColumns,
  HospDischColumns,
  IPHospitalCommColumns,
  NotesColumns,
  NurseColumns,
  OrderColumns,
  OutboundColumns,
  TherapyColumns,
  TierChangeColumns,
  TriageColumns,
  TuckInCallColumns,
} from "./ColumnsGroup";
import dayjs from "dayjs";
import { FaMobileAlt } from "react-icons/fa";
import { WarningOutlined, CopyOutlined } from "@ant-design/icons";
import { MdEventNote } from "react-icons/md";
import { formatOptionLabel } from "../../common/elements/myCustomSelect";
import { useMyContext } from "../../common/elements/myContext";

const GetModalDetails = ({
  keyProp,
  mbi,
  isVisible,
  handleOk,
  handleCancel,
  modalTitle,
  apiNode,
  patientName,
}) => {
  const { utcToLocal, formatPhoneNumber } = useMyContext();
  const modalRef = useRef(null);

  const { data, isLoading } = useSWR(`${apiNode}?id=${keyProp}`, fetcherhn);

  useEffect(() => {
    if (modalTitle === "Tuck In Call") {
      setColumns(TuckInCallColumns);
    } else if (modalTitle === "Therapy Utilization Review") {
      setColumns(TherapyColumns);
    } else if (modalTitle === "Change in Condition Note") {
      setColumns(ChangeinConditionColumns);
    } else if (modalTitle.includes("General Notes")) {
      setColumns(NotesColumns);
    } else if (modalTitle === "Inpatient Hospital Communication Note") {
      setColumns(IPHospitalCommColumns);
    } else if (modalTitle === "Admission / Re-Admission") {
      setColumns(AdmReAdmColumns);
    } else if (modalTitle === "High Risk Assessment") {
      setColumns(HighRiskColumns);
    } else if (modalTitle === "Grievance Template") {
      setColumns(GrievanceColumns);
    } else if (modalTitle === "ED Visit Template") {
      setColumns(EDColumns);
    } else if (modalTitle === "Request for Tier Change") {
      setColumns(TierChangeColumns);
    } else if (modalTitle === "Order / Request Template") {
      setColumns(OrderColumns);
    } else if (modalTitle === "DME Request") {
      setColumns(DMEColumns);
    } else if (modalTitle === "Nurse Request Template") {
      setColumns(NurseColumns);
    } else if (modalTitle === "Hospital Admission Template") {
      setColumns(HospAdmColumns);
    } else if (modalTitle === "Hospital Discharge Template") {
      setColumns(HospDischColumns);
    } else if (modalTitle === "Outbound Call") {
      setColumns(OutboundColumns);
    } else if (modalTitle === "Triage Call") {
      setColumns(TriageColumns);
    }
  }, [modalTitle]);

  const [columns, setColumns] = useState([]);
  const [expandStates, setExpandStates] = useState({
    isExpanded: false,
    isExpanded2: false,
    isExpanded3: false,
    isExpanded4: false,
    isExpanded5: false,
    isExpanded6: false,
    isExpanded7: false,
    isExpanded8: false,
    isExpanded9: false,
    isExpanded10: false,
    isExpanded11: false,
    isExpanded12: false,
    isExpanded13: false,
    isExpanded14: false,
    isExpanded15: false,
  });

  const toggleExpand = (key) => {
    setExpandStates((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const renderExpandableText = (text, key) => {
    const isExpanded = expandStates[key];
    if (!isExpanded && text?.length > 200) {
      return (
        <>
          {text.slice(0, 200)}{" "}
          <Button type="link" onClick={() => toggleExpand(key)}>
            See More
          </Button>
        </>
      );
    } else {
      return (
        <>
          {text}{" "}
          {isExpanded && (
            <Button type="link" onClick={() => toggleExpand(key)}>
              See Less
            </Button>
          )}
        </>
      );
    }
  };

  const formatSpaces = (callType) => {
    return callType.replace(/_/g, " ");
  };

  const formatMedicareAB = ({ medicareA, medicareB }) => {
    const elements = [];

    if (medicareA === "Yes") {
      elements.push(<div key="medicareA">Medicare A</div>);
    }

    if (medicareB === "Yes") {
      elements.push(<div key="medicareB">Medicare B</div>);
    }

    return elements.length > 0 ? elements : "";
  };

  const formatReviewDateAndCreatedBy = ({ reviewDate, createdBy }) => {
    const [name] = createdBy?.split("@") || [""];
    const date = reviewDate
      ? dayjs(reviewDate).format("YYYY-MM-DD hh:mm A")
      : "";
    return (
      <>
        <div>
          {date}{" "}
          {date === "1900-01-01 12:00 AM" && (
            <span className="tw-ml-2" style={{ color: "red" }}>
              <WarningOutlined />
            </span>
          )}
        </div>
        <div className="tw-font-semibold">{name}</div>
      </>
    );
  };

  const formatReviewDateTuckInCall = ({ reviewDate, createdBy }) => {
    const [name] = createdBy?.split("@") || [""];

    const formattedDate = dayjs(reviewDate).format("YYYY-MM-DD hh:mm A");

    const date =
      reviewDate && formattedDate !== "1900-01-01 12:00 AM"
        ? formattedDate
        : " ";

    return (
      <>
        <div>{date}</div>
        <div className="tw-font-semibold">{name}</div>
      </>
    );
  };

  const formatReviewDate = ({ reviewDate, createdBy }) => {
    const [name] = createdBy?.split("@") || [""];
    const date = reviewDate ? dayjs(reviewDate).format("YYYY-MM-DD") : "";
    return (
      <>
        <div>
          {date} <br /> {name}
          {date === "1900-01-01" && (
            <span className="tw-ml-2" style={{ color: "red" }}>
              <WarningOutlined />
            </span>
          )}
        </div>
      </>
    );
  };

  const formatContactInfo = (name, phoneNumber) => {
    return (
      <>
        <div>{name || ""}</div>
        {phoneNumber && (
          <div
            style={{
              fontSize: "0.9rem",
              color: "var(--bs-gray-600)",
              marginBottom: "0.3rem",
              marginTop: "0.3rem",
              overflowWrap: "break-word",
            }}
          >
            <FaMobileAlt
              size={20}
              style={{
                color: "var(--bs-gray-500)",
                marginRight: "0.5rem",
                marginTop: "-2px",
              }}
            />
            <a href={`tel:${formatPhoneNumber(phoneNumber)}`}>
              {formatPhoneNumber(phoneNumber)}
            </a>
          </div>
        )}
      </>
    );
  };

  const formatPhoneLink = (phoneNumber, callBackNumberExt) => {
    return (
      <>
        {phoneNumber ? (
          <div
            style={{
              fontSize: "0.9rem",
              color: "var(--bs-gray-600)",
              marginBottom: "0.3rem",
              marginTop: "0.3rem",
              overflowWrap: "break-word",
            }}
          >
            <a href={`tel:${formatPhoneNumber(phoneNumber)}`}>
              {formatPhoneNumber(phoneNumber)}
            </a>
            <p className="tw-text-black tw-mt-2">
              {callBackNumberExt && <> Ext. {callBackNumberExt}</>}
            </p>
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  const formatDateOfBirth = (patient) => {
    return <div>{patient?.label.split(" - ")[1] || ""}</div>;
  };

  const formatCCECompletedThisYear = (isCCECompletedThisYear) => {
    return (
      <>
        {isCCECompletedThisYear === "Yes" ? (
          <span>Yes</span>
        ) : (
          <div>
            {isCCECompletedThisYear === "No" ? "No" : "Not found in EMR"}
            <span className="tw-ml-2" style={{ color: "red" }}>
              <WarningOutlined />
            </span>
          </div>
        )}
      </>
    );
  };

  const formatLabelWithOther = (label, other) => {
    return (
      <>
        <div>{label || ""}</div>
        {label === "Other" && (
          <div className="tw-text-gray-500">
            <MdEventNote size={20} className="tw-mr-1 tw-text-gray-400" />
            {other || ""}
          </div>
        )}
      </>
    );
  };

  const HyperLink = ({ text }) => {
    return (
      <>
        {text?.split(",").map((link, index) => (
          <span key={index}>
            <a href={link.trim()}>{link.trim()}</a>
            {index < text.split(",").length - 1 ? ", " : ""}
          </span>
        ))}
      </>
    );
  };

  const renderColumnData = (column, data) => {
    const item = data?.[0];
    const iData = item?.[column.dataIndex];
    switch (column?.dataIndex) {
      case "dateOfOrderSubmoission":
      case "dateRequestSent":
      case "providerResponseDateTime":
        return item
          ? formatReviewDate({
              reviewDate: iData,
              createdBy: "",
            })
          : "";
      case "reviewDate":
        return item
          ? formatReviewDate({
              reviewDate: iData,
              createdBy: item?.createdBy,
            })
          : "";
      case "callType":
        return iData ? formatSpaces(iData) : "";
      case "medicareA":
        return item ? formatMedicareAB(item) : "";
      case "createdDateTime":
        return (
          <>
            {item ? utcToLocal(item.createdDateTime) : ""} <br />
            {item?.createdBy ? item?.createdBy.split("@")[0] : ""}
          </>
        );
      case "followupDateTime":
        return item
          ? formatReviewDateTuckInCall({
              reviewDate: iData,
              createdBy: item?.createdBy,
            })
          : "";

      case "noteDateTime":
      case "iPCommunicationNoteDateTime":
      case "assessmentDateTime":
      case "dateTimeCreated":
        return item
          ? formatReviewDateAndCreatedBy({
              reviewDate: iData,
              createdBy: item?.createdBy,
            })
          : "";
      case "attendingPhysician":
        return item
          ? formatContactInfo(
              item?.attendingPhysician,
              item?.attendingPhysicianPhoneNumber
            )
          : "";
      case "hospitalCareManager":
        return item
          ? formatContactInfo(
              item?.hospitalCareManager,
              item?.hospitalCareManagerPhoneNumber
            )
          : "";
      case "hospitalName":
        return item
          ? formatContactInfo(item?.hospitalName, item?.hospitalTelephone)
          : "";
      case "callerName":
        return item
          ? formatContactInfo(item?.callerName, item?.callerContact)
          : "";
      case "phoneNumbers":
        return item ? formatPhoneNumber(item?.phoneNumbers) : "";

      case "callBackNumber":
        return item
          ? formatPhoneLink(item?.callBackNumber, item?.callBackNumberExt)
          : "";

      case "erName":
        return item ? formatContactInfo(item?.erName, item?.erTelephone) : "";
      case "hospitalAttendingMDName":
        return item
          ? formatContactInfo(
              item?.hospitalAttendingMDName,
              item?.hospitalAttendingMDContact
            )
          : "";
      case "hospitalCaseManagerName":
        return item
          ? formatContactInfo(
              item?.hospitalCaseManagerName,
              item?.hospitalCaseManagerContact
            )
          : "";
      case "dateOfBirth":
        return item ? formatDateOfBirth(item?.patient) : "";
      case "isCCECompletedThisYear":
        return item
          ? formatCCECompletedThisYear(item?.isCCECompletedThisYear)
          : "";
      case "nporpaLabel":
        return item
          ? formatLabelWithOther(item?.nporpaLabel, item?.nporpaOther)
          : "";
      case "providerNameLabel":
        return item
          ? formatLabelWithOther(
              item?.providerNameLabel,
              item?.providerNameOther
            )
          : "";
      case "nurseSummaryNote":
        return renderExpandableText(item?.nurseSummaryNote || "", "isExpanded");
      case "therapyDetails":
        return renderExpandableText(item?.therapyDetails || "", "isExpanded2");
      case "therapyGoals":
        return renderExpandableText(item?.therapyGoals || "", "isExpanded3");
      case "caseManagerRecommendations":
        return renderExpandableText(
          item?.caseManagerRecommendations || "",
          "isExpanded4"
        );
      case "changeCondition":
        return renderExpandableText(item?.changeCondition || "", "isExpanded5");
      case "patientPlan":
        return renderExpandableText(item?.patientPlan || "", "isExpanded6");
      case "note":
        return renderExpandableText(item?.note || "", "isExpanded7");
      case "notes":
        return renderExpandableText(item?.notes || "", "isExpanded8");
      case "admittingDiagnosis":
        return renderExpandableText(
          item?.admittingDiagnosis || "",
          "isExpanded9"
        );
      case "summary":
        return renderExpandableText(item?.summary || "", "isExpanded10");
      case "callerMessage":
        return renderExpandableText(item?.callerMessage || "", "isExpanded11");
      case "erVisitSymptonDx":
        return renderExpandableText(
          item?.erVisitSymptonDx || "",
          "isExpanded12"
        );
      case "medxNotes":
        return renderExpandableText(item?.medxNotes || "", "isExpanded13");
      case "reasonForTierChange":
        return renderExpandableText(
          item?.reasonForTierChange || "",
          "isExpanded14"
        );
      case "requestungDetails":
        return renderExpandableText(
          item?.requestungDetails || "",
          "isExpanded15"
        );
      case "comments":
        return renderExpandableText(item?.comments || "", "isExpanded16");
      case "createdBy":
        if (
          (modalTitle === "Grievance Template" ||
            modalTitle === "ED Visit Template" ||
            modalTitle === "Request for Tier Change" ||
            modalTitle === "Order / Request Template" ||
            modalTitle === "ED Visit Template" ||
            modalTitle === "DME Request" ||
            modalTitle === "Nurse Request Template" ||
            modalTitle === "Hospital Admission Template" ||
            modalTitle === "Hospital Discharge Template" ||
            modalTitle === "Outbound Call") &&
          iData
        ) {
          const [name] = iData.split("@");
          return <div className="tw-font-semibold">{name}</div>;
        } else {
          return iData || "";
        }
      case "isThereNewMedication":
        return iData === "Yes" ? (
          <>
            Yes <br />
            {item?.newMedicationNotes}
          </>
        ) : iData === "Unk" ? (
          "Unknown"
        ) : (
          "No"
        );
      case "isMedicationArrived":
        return iData === "No" ? (
          <>
            No <br />
            {item?.medicationDeliveryNotes}
          </>
        ) : iData === "Unk" ? (
          "Unknown"
        ) : (
          "Yes"
        );
      case "codeStatusOnFile":
        return iData === "Unk" ? (
          <>
            Unknown <br />
          </>
        ) : (
          iData
        );
      case "isNCPFollowUpCallSetUpDone":
        return iData === "No" ? (
          <>
            No <br />
            {item?.ncpFollowUpCallNotes}
          </>
        ) : (
          "Yes"
        );
      case "location":
        return iData === "Other" ? (
          <>
            {iData} <br />
            {item?.locationOtherNotes}
          </>
        ) : (
          `${iData}`
        );
      case "attachmentLinks":
        return item?.attachmentLinks ? (
          <HyperLink text={item.attachmentLinks} />
        ) : (
          ""
        );
      default:
        return iData || "";
    }
  };

  const handleCopy = () => {
    if (modalRef.current) {
      const range = document.createRange();
      range.selectNodeContents(modalRef.current);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      try {
        document.execCommand("copy");
        selection.removeAllRanges();
        message.success("Copied to clipboard!");
      } catch (err) {
        message.error("Failed to copy!");
      }
    }
  };

  return (
    <>
      <Modal
        open={isVisible}
        onOk={handleOk}
        loading={isLoading}
        onCancel={handleCancel}
        title={
          <div className="">
            <div className="tw-text-xl">
              {modalTitle}{" "}
              <Tooltip title="Copy to Clipboard">
                <CopyOutlined
                  onClick={handleCopy}
                  className=" tw-cursor-pointer"
                />
              </Tooltip>
            </div>
            <div>{formatOptionLabel({ label: patientName })}</div>
            <Divider className="tw-mt-2 tw-mb-2" />
          </div>
        }
        width={1200}
        footer={false}
        // footer={[
        //   <Button key="close" danger onClick={handleCancel}>
        //     Close
        //   </Button>,
        // ]}
      >
        {data && (
          <div
            className="tw-grid tw-grid-cols-3 tw-gap-4"
            id="summary-content"
            ref={modalRef}
          >
            {columns.map((column) => (
              <div key={column.dataIndex}>
                <div className="tw-font-semibold tw-text-lg">
                  {column.title}
                </div>
                <div>{renderColumnData(column, data)}</div>
              </div>
            ))}
          </div>
        )}
      </Modal>
    </>
  );
};

export default GetModalDetails;
