import React, { useEffect, useState } from "react";
import MyCustomSelect from "../../common/elements/myCustomSelect";
import { DatePicker } from "antd";
import dayjs from "dayjs";

import { usePickList } from "../../services/queries";
import { postCMN } from "../../api/CareEventsApi";
import { openNotification } from "../../common/elements/myNotification";
import TextArea from "antd/es/input/TextArea";
const dateFormat = ["YYYY-MM-DD hh:mm A"];

export const AddNote = ({ editRecord, mbiid, callback }) => {
  const noteType = usePickList(23).data;
  const intialReqState = {
    key: "",
    guid: "",
    mbi: mbiid,
    noteType: "",
    noteLabel: "",
    note: "",
    noteDateTime: dayjs().format(dateFormat[0]),
    createdBy: sessionStorage.getItem("userName"),
    modifiedBy: "",
  };
  const [formData, setFormData] = useState(intialReqState);
  const [pageError, setPageError] = useState({});
  const [disable, setDisable] = useState(false);
  const [typeDisable, setTypeDisable] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState(dayjs());
  const [selectedNoteType, setSelectedNoteType] = useState("");

  useEffect(() => {
    if (editRecord?.guid) {
      setTypeDisable(true);
      setFormData({
        ...editRecord,
        modifiedBy: sessionStorage.getItem("userName"),
      });
      setSelectedDateTime(
        dayjs(editRecord?.noteDateTime) || dayjs().format(dateFormat[0])
      );
      setSelectedNoteType(
        editRecord
          ? { label: editRecord.noteLabel, value: editRecord.noteType }
          : ""
      );
    } else {
      setFormData(intialReqState);
      setTypeDisable(false);
    }
  }, [editRecord]);

  const noteTypeChange = (e) => {
    setSelectedNoteType(e);
    setFormData({ ...formData, noteType: e ? e.value : "" });
    setPageError({ ...pageError, noteType: e ? "" : "Required" });
  };

  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    setPageError({ ...pageError, [name]: value ? "" : "Required" });
  };

  const onDateChange = (date, dateString) => {
    setSelectedDateTime(date || "");
    setFormData({
      ...formData,
      noteDateTime: dateString ? dateString : "",
    });
    setPageError({
      ...pageError,
      noteDateTime: dateString ? "" : "Required",
    });
  };

  const onSubmit = (e) => {
    setPageError({});
    let pageErrorTemp = {};
    if (!formData?.noteDateTime || !formData?.note) {
      pageErrorTemp.noteDateTime = formData?.noteDateTime ? "" : "Required";
      pageErrorTemp.note = formData?.note ? "" : "Required";
      setPageError(pageErrorTemp);
      return;
    }
    onSave(e);
  };

  const onSave = async (e) => {
    setDisable(true);
    try {
      const response = await postCMN(formData);
      if (response?.status === 200 && !formData?.guid) {
        openNotification(
          "success",
          "Added",
          "General Notes Added Successfully!"
        );
          setFormData(intialReqState);
          setSelectedDateTime(dayjs());
          callback(response); 
      } else if (formData?.guid && response?.status === 200) {
        openNotification(
          "success",
          "Updated",
          "General Notes Updated Successfully!"
        );
          setFormData(intialReqState);
          setSelectedDateTime(dayjs());
          callback(response); 
      } else {
        openNotification("error", "Error", "General Notes Failed to Add!");
      }
      // setFormData(intialReqState);
      // setSelectedDateTime(dayjs());
      // callback(response);
    } catch (error) {
       // return e;       
       openNotification("error", "Error", "An unexpected error occurred!");
       console.error("Save error:", error);
    }
    finally {
       setDisable(false);
    }
  };

  return (
    <div className="tw-relative tw-px-2">
      <div className="tw-flex tw-flex-col tw-mb-3 ">
        <label className="tw-font-bold" htmlFor="noteType">
          Note Type
        </label>

        <MyCustomSelect
          disable={typeDisable}
          name="CM NoteType"
          selectOptions={noteType}
          myCallBack={noteTypeChange}
          selectedValue={selectedNoteType}
          errorMessage={pageError?.noteType}
        />
      </div>
      {selectedNoteType?.value && (
        <>
          <div className="tw-flex tw-flex-col tw-mb-3">
            <label className="tw-font-bold" htmlFor="changeCondition">
              {selectedNoteType?.label}
            </label>
            <TextArea
              autoSize={{
                minRows: 10,
                maxRows: 20,
              }}
              className=" form-control"
              id="changeCondition"
              name={selectedNoteType?.value}
              value={formData?.note}
              onChange={(e) => handleInputChange("note", e.target.value)}
              style={{
                border: pageError?.note ? "1px solid red" : "",
              }}
            />
          </div>
          <div className="tw-flex ">
            <label className="tw-font-bold" htmlFor="dateTime">
              Note Date & Time
            </label>
          </div>
          <DatePicker
            showTime
            use12Hours
            className="form-control tw-mb-3"
            onChange={onDateChange}
            format={dateFormat}
            value={selectedDateTime}
            style={{
              border: pageError?.noteDateTime ? "1px solid red" : "",
            }}
          />
          <div className="form-group tw-fixed tw-top-2 tw-right-10">
            <button
              className={
                !disable ? "btn btn-success " : "btn btn-success disabled "
              }
              onClick={onSubmit}
            >
              {editRecord?.guid ? "Update" : "Save"}
            </button>
            <button
              className="btn btn-secondary tw-ml-4"
              onClick={() => callback(false)}
            >
              Close
            </button>
          </div>
        </>
      )}
    </div>
  );
};
